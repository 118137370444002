<template>
    <div>
        <fieldset class="fieldset">
            <b-form-group label="Identifiant">
                <b-form-input type="text" v-model="identifiant" @keypress="cleanError"/>
            </b-form-group>

            <b-form-group label="Mot de passe">
                <b-form-input type="password" v-model="password" @keypress="cleanError"/>
            </b-form-group>

            <b-button :disabled="!canLogin" @click="submitCredentials" variant="primary">Connexion</b-button>
            <b-alert
              variant="danger"
              v-show="lastLoginError != null && lastLoginError != ''"
            >{{ lastLoginError }}</b-alert>
        </fieldset>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data(){
        return {
            identifiant: '',
            password: ''
        }
    },
    computed: {
        ...mapGetters('auth', ['lastLoginError']),
        canLogin(){
            return this.identifiant.trim() != '' && this.password.trim() != '';
        }
    },
    methods:{
        ...mapActions('auth', ['login']),
        ...mapMutations('auth', ['setLastLoginError']),
        submitCredentials(){
            const user = { loginName: this.identifiant, password: this.password };
            this.login(user);
        },
        cleanError(){
            this.setLastLoginError('');
        }
    },
    mounted(){
        this.cleanError();
        this.$hideLoader();
    }
}
</script>
